import * as client_hooks from '../../../src/entry/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)/(root)/(organization)": [13,[2,3]],
		"/(not-authenticated)/(deprecated)/access/[project]": [67,[12]],
		"/(not-authenticated)/(deprecated)/access/[project]/role/[role]": [68,[12]],
		"/(not-authenticated)/(deprecated)/audit": [69,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]": [70,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]/[phase]": [71,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]/[phase]/criterion-[criterion]/[iteration]": [72,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]/[phase]/subject-[subject]/[iteration]": [73,[12]],
		"/(not-authenticated)/callback": [94,[12]],
		"/(authenticated)/create-project": [27,[2]],
		"/(authenticated)/download": [28,[2]],
		"/(authenticated)/edit": [29,[2,6]],
		"/(authenticated)/edit/create-manual": [30,[2,6]],
		"/(authenticated)/edit/manual-view/[manual]": [31,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/config": [32,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/faq": [33,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/manual-structure": [34,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/object-view": [35,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/criterion/[id]": [36,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/criterion/[id]/cms": [37,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/field/[id]": [38,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/field/[id]/cms": [39,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/subject/[id]": [40,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/subject/[id]/cms": [41,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/template/[id]": [42,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/template/[id]/cms": [43,[2,6,7,8]],
		"/(authenticated)/edit/onboarding/[id]": [44,[2,6]],
		"/(not-authenticated)/(deprecated)/forgot-password": [74,[12]],
		"/(not-authenticated)/invitation/[key]": [95,[12]],
		"/(not-authenticated)/(deprecated)/log-in": [75,[12]],
		"/(authenticated)/(root)/(organization)/organization/create": [21,[2,3]],
		"/(authenticated)/(root)/(organization)/organization/move-existing-project": [22,[2,3]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/portfolios": [18,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/quarterly-report": [19,[2,3,4]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/organization/general": [23,[2,5]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/organization/members": [24,[2,5]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/portfolio/[portfolio]/general": [25,[2,5]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/portfolio/[portfolio]/members": [26,[2,5]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/statistics": [20,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/assets": [14,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/assets/[asset]": [15,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/certificates": [16,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/certifications": [17,[2,3,4]],
		"/(not-authenticated)/(deprecated)/pre-analysis/breeam/[project]": [76,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]": [90,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]/[phase]": [91,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]/[phase]/[category]": [92,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]/[phase]/[category]/[subject]": [93,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]": [78,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/audit": [79,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/editor/pdf/[id]": [80,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/overview": [81,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/overview/edit": [82,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings": [83,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/general": [84,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/notifications": [85,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/profile": [86,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/properties": [87,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/roles": [88,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/users": [89,[12]],
		"/(authenticated)/project/[manual]/[project]": [45,[2]],
		"/(authenticated)/project/[manual]/[project]/[phase]": [46,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit": [47,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit/approved-documents": [48,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit/criterion/[criterion]": [49,[2,9]],
		"/(not-authenticated)/(deprecated)/project/[manual]/[project]/[phase]/audit/documentation": [77,[12]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit/subject/[subject]": [50,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/criterion/[criterion]": [51,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/overview": [52,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/overview/edit": [53,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings": [54,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/general": [55,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/language": [56,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/notifications": [57,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/profile": [58,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/properties": [59,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/roles": [60,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/users": [61,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/workflow": [62,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/statistics": [63,[2,9,11]],
		"/(authenticated)/project/[manual]/[project]/[phase]/subject/[subject]": [64,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/tasks": [65,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/uploaded-documents": [66,[2,9]],
		"/(not-authenticated)/redirect": [96,[12]],
		"/(not-authenticated)/unsupported-browser": [97,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';